@import "bootstrap-override";
@import "admin-caja";

a,
.open a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

body {
  // -webkit-tap-highlight-color: transparent;
  // -webkit-user-select: none;
  -webkit-touch-callout: none;
  min-width: 1024px;

  .navbar {
    min-height: 30px;
    margin-bottom: 0;
  }

  &.mobile {
    #jg_sidebar {
      position: absolute;
      left: 0;
      height: 100%;
      border-right: 0px;
      box-shadow: none;
    }

    #jg_content {
      margin-left: 220px;
      position: fixed;
      width: 850px;
    }
  }

  .capitalize {
    text-transform: capitalize;
  }

  .estado_ruta {
    text-align: center;
    font-size: 24px;
  }

  #nprogress {
    .spinner {
      display: none !important;
    }
  }
}

#jg_header {
  height: 30px;

  .navbar-toggle {
    padding: 15px 10px;
    margin: 6px 15px 0 2px;
    background-color: #2c2c2c;

    .icon-bar {
      width: 38px;
      background-color: #ffffff;
    }
  }

  #jg_toolbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 30px;
    padding: 0 3px;
    border: 0;
    margin: 0;
    text-align: center;
    color: #fff;
    background: #3a4450;
    z-index: 5000;

    > li {
      display: inline-block;
      position: relative;
      line-height: 20px;

      > a {
        display: inline-block;
        padding: 5px 10px 0 10px;
        font-size: 11px;
        font-weight: bold;
        text-transform: uppercase;
        color: #ffffff;
        height: 30px;
        line-height: 21px;

        &:hover {
          text-decoration: none;
          background: #4b5867;
        }
      }

      &.pull-right > a {
        margin-left: 10px;
      }

      &.parent > a {
        > span:after {
          content: "";
          display: inline-block;
          width: 0px;
          height: 0px;
          border-width: 5px;
          border-style: solid;
          border-color: #ffffff #3a4450 #3a4450 #3a4450;
          position: relative;
          top: 1px;
          position: relative;
          top: 2px;
          margin-left: 5px;
        }

        &:hover > span:after {
          border-color: #ffffff #4b5867 #4b5867 #4b5867;
        }
      }
    }

    .dropdown-menu {
      top: 98%;
      right: 0;
      margin: 0;
      padding: 0 0 0 0;
      background: #3a4450;
      border: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      box-shadow: none;
      text-align: left;

      * {
        color: #ffffff;
      }

      > li {
        > a {
          height: 30px;
          filter: none;

          &:hover {
            background: #4b5867;
          }
        }

        &.divider {
          background: #4b5867;
        }
      }
    }

    h1 {
      position: relative;
      height: 30px;
      margin: 0;
      top: 2px;
      font-size: 14px;
      font-weight: normal;

      [class^="icon-"] {
        position: relative;
        top: 1px;
        margin-right: 1px;
        font-size: 18px;
        line-height: 18px;
        color: #6ac8fb;
      }

      a {
        position: relative;
        top: 3px;
        color: #ffffff;
        text-decoration: none !important;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

#jg_sidebar {
  position: fixed;
  width: 220px;
  height: 100%;
  padding: 0 0 70px 0;
  background: url(../img/bg-sidebar.png) #61b0e9 no-repeat 0 390px;
  z-index: 1000;
  -webkit-transition: 0.3s left ease;
  -moz-transition: 0.3s left ease;
  -ms-transition: 0.3s left ease;
  -o-transition: 0.3s left ease;
  transition: 0.3s left ease;

  .jg_sidebar_menu {
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      position: relative;
      list-style: none;
      padding: 0;
      margin: 0;

      > li {
        width: 220px;
      }

      > li > div {
        width: 220px;
        position: relative;
        background: #61b0e9;
      }

      > li > div > a {
        position: relative;
        display: block;
        padding: 10px;
        color: #ffffff;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 12px;
      }

      > li > div > a > [class^="icon-"],
      > li > div > a > [class*=" icon-"] {
        display: inline-block;
        width: 1.23em;
        margin-right: 10px;
        font-size: 28px;
        line-height: 28px;
        font-weight: normal;
        text-align: center;
      }

      > li > div > a > span {
        position: relative;
        top: 6px;
      }

      > li > div > a > [class^="icon-"] + span {
        position: relative;
        top: -4px;
      }

      > li:hover > div > a {
        background: #6bbef6;
      }

      > li.active > div > a {
        background: #ffffff;
        color: #20202f;
      }

      > li.parent > div.active > ul.dropdown-menu {
        display: block;
        position: relative;
        top: 0 !important;
        left: 0% !important;
        right: 0% !important;
        width: 100%;
        padding: 10px 0 10px;
        margin-bottom: 15px;
        background: #6bbef6;
        border: none;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0;
      }

      > li.parent > div.active > ul.dropdown-menu:before {
        position: absolute;
        top: -7px;
        left: 34%;
        display: inline-block;
        border-right: 9px solid transparent;
        border-bottom: 7px solid #ccc;
        border-left: 9px solid transparent;
        border-bottom-color: #6bbef6;
        content: "";
      }

      > li.parent > div.active > ul.dropdown-menu > li {
        padding-left: 5px;
      }

      > li.parent > div.active > ul.dropdown-menu > li > a {
        padding: 5px 16px;
        color: #ffffff;
        font-size: 14px;
        text-transform: none;
        background: none;
      }

      > li.parent > div.active > ul.dropdown-menu > li:hover > a {
        padding-left: 8px !important;
        border-left: 8px solid #aedcfb;
      }

      > li.parent > div > a > span:before {
        content: "";
        display: inline-block;
        width: 0px;
        height: 0px;
        border-width: 5px;
        border-style: solid;
        border-color: #61b0e9 #61b0e9 #61b0e9 #ffffff;
        margin-right: 5px;
      }

      > li.parent:hover > div > a > span:before,
      > li.parent:hover > div > a > span:before {
        border-color: #6bbef6 #6bbef6 #6bbef6 #ffffff;
      }

      > li.parent:hover > div > ul > li > a {
        padding: 5px 16px;
        color: #ffffff;
        font-size: 14px;
        text-transform: none;
        background: none;
      }

      > li > div > a {
        text-decoration: none;
      }
    }

    > li {
      width: 220px;
    }

    > li > div {
      width: 220px;
      position: relative;
      background: #61b0e9;
    }

    > li > div > a {
      position: relative;
      display: block;
      padding: 10px;
      color: #ffffff;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 12px;
    }

    > li > div > a > [class^="icon-"],
    > li > div > a > [class*=" icon-"] {
      display: inline-block;
      width: 1.23em;
      margin-right: 10px;
      font-size: 28px;
      line-height: 28px;
      font-weight: normal;
      text-align: center;
    }

    > li > div > a > span {
      position: relative;
      top: 6px;
    }

    > li > div > a > [class^="icon-"] + span {
      position: relative;
      top: -4px;
    }

    > li:hover > div > a {
      background: #6bbef6;
    }

    > li.active > div > a {
      background: #ffffff;
      color: #20202f;
    }

    > li.parent > div.active > ul.dropdown-menu {
      display: block;
      position: relative;
      top: 0 !important;
      left: 0% !important;
      right: 0% !important;
      width: 100%;
      padding: 10px 0 10px;
      margin-bottom: 15px;
      background: #6bbef6;
      border: none;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -o-border-radius: 0;
      border-radius: 0;
    }

    > li.parent > div.active > ul.dropdown-menu:before {
      position: absolute;
      top: -7px;
      left: 34%;
      display: inline-block;
      border-right: 9px solid transparent;
      border-bottom: 7px solid #ccc;
      border-left: 9px solid transparent;
      border-bottom-color: #6bbef6;
      content: "";
    }

    > li.parent > div.active > ul.dropdown-menu > li {
      padding-left: 5px;
    }

    > li.parent > div.active > ul.dropdown-menu > li > a {
      padding: 5px 16px;
      color: #ffffff;
      font-size: 14px;
      text-transform: none;
      background: none;
    }

    > li.parent > div.active > ul.dropdown-menu > li:hover > a {
      padding-left: 8px !important;
      border-left: 8px solid #aedcfb;
    }

    > li.parent > div > a > span:before {
      content: "";
      display: inline-block;
      width: 0px;
      height: 0px;
      border-width: 5px;
      border-style: solid;
      border-color: #61b0e9 #61b0e9 #61b0e9 #ffffff;
      margin-right: 5px;
    }

    > li.parent:hover > div > a > span:before,
    > li.parent:hover > div > a > span:before {
      border-color: #6bbef6 #6bbef6 #6bbef6 #ffffff;
    }

    > li.parent:hover > div > ul > li > a {
      padding: 5px 16px;
      color: #ffffff;
      font-size: 14px;
      text-transform: none;
      background: none;
    }

    > li > div > a {
      text-decoration: none;
    }
  }
}

#jg_content {
  position: absolute;
  top: 40px;
  right: 20px;
  left: 240px;
  width: auto;
  min-width: 800px;
  margin: 0;
  padding-bottom: 20px;
  font-size: 12px;
  -webkit-transition: 0.3s margin-left ease;
  -moz-transition: 0.3s margin-left ease;
  -ms-transition: 0.3s margin-left ease;
  -o-transition: 0.3s margin-left ease;
  transition: 0.3s margin-left ease;

  h2 {
    margin-top: 0;
  }

  input.jg_orden {
    min-width: 50px;
  }

  td {
    &.actions,
    &.actions_related_item {
      width: 230px;
    }
  }

  div.actions {
    display: none;
  }

  .asc i,
  .desc i {
    margin-left: 5px;
  }

  .pagination {
    margin-top: 10px;
  }

  blockquote small {
    color: #6c6c6c;
  }

  th,
  td {
    text-align: center;
  }

  tfoot td {
    text-align: center;

    .pagination {
      margin: 5px 0 2px;
    }
  }
}

#panel-login {
  width: 345px;
  margin: 60px auto 20px;

  header {
    padding: 0 10px;
    height: 30px;
    color: #ffffff;
    text-decoration: none;
    background: #3a4450;

    i {
      position: relative;
      top: 6px;
      margin-right: 5px;
      font-size: 17px;
      line-height: 17px;
    }

    span {
      position: relative;
      top: 5px;
    }
  }

  .content {
    padding: 30px;
    color: #3a4450;
    border: 1px solid #f0f0fb;
    background: #f7f7fd;

    label {
      display: block;
      margin-bottom: 5px;
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
    }

    .btn.btn-primary {
      background: #61b0e9;
      color: #ffffff;
      text-transform: uppercase;
      font-size: 14px;
      border-color: #389ae2;
      text-shadow: none;
      border-radius: 0;
      filter: none;
    }
  }
}

.panel {
  margin: 0 auto 20px auto;

  h3 {
    color: #fff;
    padding: 0 10px;
    margin: 0;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    background-color: #3a4450;
  }

  .content {
    padding: 10px;
    color: #3a4450;
    border: 1px solid #f0f0fb;
    text-align: center;
    background: #f7f7fd;

    .btn-shortcut {
      display: inline-block;
      width: 105px;
      height: 85px;
      margin: 5px;
      color: #ffffff;
      text-align: center;
      text-decoration: none !important;
      background: #61b0e9;
      -webkit-transition: 300ms background ease;
      -moz-transition: 300ms background ease;
      -ms-transition: 300ms background ease;
      -o-transition: 300ms background ease;
      transition: 300ms background ease;

      i {
        display: block;
        font-size: 32px;
        line-height: 32px;
        margin-top: 14px;
      }

      span {
        display: block;
        margin-top: 10px;
      }

      &:hover {
        text-decoration: none !important;
        background: #6bbef6;
        color: #ffffff;
      }

      &.block-orange {
        background: #ed6d49;
      }

      &.block-yellow {
        background: #ffb400;
      }

      &.block-blue {
        background: #0daed3;
      }

      &.block-primary {
        background: #428bca;
      }

      &.block-red {
        background: #f63131;
      }

      &.block-green {
        background: #74b749;
      }

      &.block-black {
        background: #2c2c2c;
      }
    }
  }
}

#alertInstallPwa {
  display: none;
  width: 380px;
  position: fixed;
  margin-bottom: 0;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}

@media (max-width: 767px) {
  body {
    min-width: 0;
  }

  #jg_sidebar {
    left: -220px;
    padding-top: env(safe-area-inset-top);
    position: absolute;
  }

  #jg_header {
    height: auto;
    min-height: 70px;

    #jg_toolbar {
      height: auto;
      min-height: 70px;
      // padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
      padding-left: 3px;
      padding-right: 3px;
      padding-top: env(safe-area-inset-top);

      > li {
        line-height: 70px;
      }

      h1 {
        top: 1px;
        height: 70px;
        line-height: 65px;
      }
    }
  }

  #jg_content {
    top: 70px;
    right: 0px;
    left: 0px;
    min-width: 0;
    padding: 20px;
    padding-top: env(safe-area-inset-top);

    blockquote small {
      color: #6c6c6c;
      font-size: 12px;
    }
  }
}

@media (max-width: 480px) {
  #jg_content {
    padding: 10px;
    padding-top: env(safe-area-inset-top);
  }

  #alertInstallPwa {
    width: calc(100% - 40px);
  }
}

@media (min-width: 768px) {
  #jg_sidebar .jg_sidebar_menu {
    > li:hover ul.dropdown-menu {
      display: block;
      position: absolute;
      top: 0 !important;
      left: 100% !important;
      padding: 10px 0;
      background: #6bbef6;
      border: none;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -o-border-radius: 0;
      border-radius: 0;
    }

    li {
      > li:hover ul.dropdown-menu {
        display: block;
        position: absolute;
        top: 0 !important;
        left: 100% !important;
        padding: 10px 0;
        background: #6bbef6;
        border: none;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0;
      }

      > li:hover ul.dropdown-menu > li > a {
        padding: 5px 16px;
        color: #ffffff;
        font-size: 14px;
        text-transform: none;
        background: none;
      }

      > li:hover ul.dropdown-menu > li:hover > a {
        padding-left: 8px !important;
        border-left: 8px solid #aedcfb;
      }
    }

    > li:hover ul.dropdown-menu > li > a {
      padding: 5px 16px;
      color: #ffffff;
      font-size: 14px;
      text-transform: none;
      background: none;
    }

    > li:hover ul.dropdown-menu > li:hover > a {
      padding-left: 8px !important;
      border-left: 8px solid #aedcfb;
    }
  }
}

.select2-container-multi .select2-choices li {
  display: none;
}

.ui-sortable > tr .jg_handle {
  cursor: move;
}

/*----- Ocultando botones del formulario de abonos ---*/
#btn_noabonado {
  display: none;
}

#AbonoAddForm {
  input[type="submit"] {
    display: none;
  }
}
/*-----------------------------------------------------*/
